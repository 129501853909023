import $ from "jquery";

$(document).ready(function() {
    $('#closeModalBtn').on('click', function() {
        localStorage.setItem('city', 'almaty');
        $.magnificPopup.close();

        const protocol = window.location.protocol;
        const host = window.location.host;
        const port = window.location.port;

        let origin = protocol + '//' + host;
        if (port) {
            origin += '/ru/almaty';
        }

        console.log(origin);
        if($(location).attr("pathname").split('/').pop() !== 'register-promo') {
            setTimeout(function() {
                location.replace(origin);
            }, 1000);
        }

    });
});
