import $ from 'jquery';

$(document).ready(function () {
  $('#contentEdit').click(function () {
    const editable = document.getElementById('contentEditable');
    editable.setAttribute('contenteditable', true);
    $('#contentEdit').hide();
    $('#contentSave').show();
    $('#contentCancel').show();
  });

  $('#contentCancel').click(function () {
    document.getElementById('contentEditable').setAttribute('contenteditable', false);
    $('#contentEdit').show();
    $('#contentSave').hide();
    $('#contentCancel').hide();
  });

  $('#contentSave').click(function () {
    const html = document.getElementById('contentEditable').innerHTML;
    const itemID = document.getElementById('contentSave').getAttribute('data-id');
    const lang = document.getElementById('contentSave').getAttribute('data-lang');
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
    });
    $.ajax({
      method: 'POST',
      url: `/page-content/edit/${itemID}`,
      data: {
        html,
        lang,
      },
    })
      .done(function() {
        document.getElementById('contentEditable').setAttribute('contenteditable', false);
        $('#contentSave').hide();
        $('#contentCancel').hide();
        $('#contentEdit').show();
        $.notify('I am the Body');
    })
      .fail(function(jqXHR, textStatus) {
      alert(`Request failed: ${textStatus}`);
    });
  });
});
