import $ from 'jquery';

const onError = (err) => {
  switch (err.status) {
    case 422:
      // you can loop through the errors object and show it to the user
      // display errors on each form field
      $.each(err.responseJSON.errors, (i, error) => {
        const el = $(document).find(`[name="${i}"]`);
        el.after($(`
            <div class='result-message alert alert-danger alert-block'>
              <strong>${error[0]}</strong>
            </div>
        `));
      });
      break;
    case 403:
      $(() => {
        const el = $(document).find('[name="password"]');
        el.after($(`<span class="errorMessage" style="color: red;">${err.responseJSON.message}</span>`));
      });
      break;
    default:
      alert('Введите правильные значения');
  }
};

$(document).ready(() => {
  $("form[name='loginFront']").submit(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.inputBl .result-message').remove();
    const form = $(this).serialize();
    const htmlLang = document.documentElement.lang;

    $.ajax({
      url: $(this).attr('action'),
      data: {
        form, htmlLang,
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
      .done((data) => {
        alert(data.message);
        window.location.replace(data.redirectUrl);
      })
      .fail((err) => {
        onError(err);
      });
  });

  const togglePassword = document.querySelector('#togglePassword');
  const password = document.querySelector('#auth_password');
  togglePassword.style.margin = "10px 0 0 5px";
  togglePassword.addEventListener('click', function (e) {
    // toggle the type attribute
    const type = togglePassword.checked ? 'text' : 'password';
    password.setAttribute('type', type);
  });

  // Zhanuzak
  $("form[name='loginReceipt']").submit(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.errorMessage').remove();
    const form = $(this).serialize();
    const htmlLang = document.documentElement.lang;
    let promoId = $('#promo_id').val();
    let prize = $('#prize').val();
    console.log(form);
    $.ajax({
      url: $(this).attr('action'),
      data: {
        form, htmlLang, promoId, prize
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
    .done((data) => {
      const baseUrl = window.location.origin;
      const url = window.location.pathname;
      const stockArr = url.split('/');
      const stockName = `/${ stockArr[stockArr.length - 1]}`;
      $.ajax({
          //url: `${baseUrl}/promo-login`,
        url: `${baseUrl}/ru/almaty/promo-login`,
        data: {
          stockName, htmlLang, promoId, prize
        },
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        method: 'POST',
        dataType: 'JSON',
      })
      .done((promoData) => {
        window.location.replace(promoData.redirectUrl);
        alert(data.message);
      });
    })
    .fail((err) => {
      onError(err);
    });
  });

  $("form[name='registerFront']").submit(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.errorMessage').remove();
    const form = $(this).serialize();
    const htmlLang = document.documentElement.lang;
    let promoId = $('#promo_id').val();
    if(promoId == null){
        promoId = 0;
    }



    $.ajax({
      url: $("form[name='registerFront']").attr('action'),
      data: {
        form, htmlLang, promoId
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
      .done((data) => {
        window.location.replace(data.redirectUrl);
      })
      .fail((err) => {
        onError(err);
      });
  });
  $('input').on('input', () => {
    $('.errorMessage').remove();
  });

  $('.popup-with-move-anim').on('click', () => {
    $('.errorMessage').remove();
  });

  $("form[name='forgotPasswordFront']").submit(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.errorMessage').remove();
    const email = $("form[name='forgotPasswordFront'] input[name='email']").val();

    $.ajax({
      url: $(this).attr('action'),
      data: {
        email,
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
      .done((data) => {
        alert(data.message);
      })
      .fail((err) => {
        onError(err);
      });
  });
  $('#editUser').on('submit', (e) => {
    e.preventDefault();
    e.stopPropagation();
    $('.errorMessage').remove();
    const form = $('#editUser').serialize();
    const htmlLang = document.documentElement.lang;
    $.ajax({
      url: $('#editUser').attr('action'),
      data: {
        form, htmlLang,
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
      .done((data) => {
        alert(data.message);
        window.location.replace(data.redirectUrl);
      })
      .fail((err) => {
        onError(err);
      });
  });

  // ESF
  // $('#esfResult').on('submit', (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   $('.loading').remove();
  //   $('.result-message').remove();
  //   $('.errorMessage').remove();
  //   const form = $('#esfResult').serialize();
  //
  //   // For Attorney new fields
  //   // const form = $('#esfResult').closest('form');
  //   // const formData = new FormData(form[0]);
  //
  //   const el = $(document).find('[name="date"]');
  //   el.after($('<span class="loading">Загрузка...</span>'));
  //   $.ajax({
  //     url: $('#esfResult').attr('action'),
  //     data: form,
  //     headers: {
  //       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     type: 'POST',
  //     dataType: 'JSON',
  //   })
  //   .done((data) => {
  //     $('.loading').remove();
  //     if (data.status === 'success_message') {
  //       $('#receiptStep3').toggleClass('receiptRegister__step--active');
  //       $('#receiptStep2Old').toggleClass('receiptRegister__step--deactive');
  //       $('#stepStatus2').removeClass('blockSteps__step--active');
  //       $('#stepStatus3').toggleClass('blockSteps__step--active');
  //       $('#invoiceTable tr:last')
  //         .after(`
  //           <tr>
  //             <td>${data.data.reg_number}</td>
  //             <td>${data.data.type}</td>
  //             <td>${data.data.factura}</td>
  //             <td>${data.data.date}</td>
  //             <td>${data.data.cash_n}</td>
  //             <td>${data.data.check_n}</td>
  //             <td>${data.data.sum_tng}</td>
  //             <td>${data.data.status}</td>
  //             <td>
  //               <a href="/receipt/${data.data.id}/pdf" target="_blank" id="getCheck-{{ $invoice->id }}" class="btn-sm">
  //                   <img src="https://small.kz/images/icons/invoice_receipt.png" alt="">
  //               </a>
  //               <a href="/waybill/${data.data.id}/pdf" target="_blank" id="getCheck-{{ $invoice->id }}" class="btn-sm">
  //                   <img src="https://small.kz/images/icons/invoice_waybill.png" alt="">
  //               </a>
  //             </td>
  //           </tr>`);
  //     }
  //       el.after($(`
  //         <div class='result-message alert alert-${data.type} alert-block'>
  //           <strong>${data.message}</strong>
  //         </div>`));
  //     })
  //     .fail((err) => {
  //       console.log(err);
  //       $('.loading').remove();
  //       onError(err);
  //     });
  // });
  //
  //
  // // New type esf
  // $('#newTypeESFForm').on('submit', (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   $('.loading').remove();
  //   $('.result-message').remove();
  //   $('.errorMessage').remove();
  //
  //   const form = document.getElementById('newTypeESFForm');
  //   let formData = new FormData(form);
  //   const file_name = $('#fileInput')[0].files[0].name;
  //   formData.append('file_name', file_name);
  //   // For Attorney new fields
  //   // const form = $('#esfResult').closest('form');
  //   // const formData = new FormData(form[0]);
  //
  //   // const htmlLang = document.documentElement.lang;
  //   const el = $(document).find('#quField');
  //   el.after($('<span class="loading">Загрузка...</span>'));
  //   $.ajax({
  //     url: $('#newTypeESFForm').attr('action'),
  //     data: formData,
  //     headers: {
  //       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     type: 'POST',
  //     dataType: 'JSON',
  //     processData: false,
  //     contentType: false,
  //   })
  //   .done((data) => {
  //     $('.loading').remove();
  //     if (data.status === 'success_message') {
  //       $('#receiptStep3').toggleClass('receiptRegister__step--active');
  //       $('#receiptStep2New').toggleClass('receiptRegister__step--deactive');
  //       $('#stepStatus2').removeClass('blockSteps__step--active');
  //       $('#stepStatus3').toggleClass('blockSteps__step--active');
  //       $('#invoiceTable tr:last')
  //           .after(`
  //       <tr>
  //         <td>${data.data.reg_number}</td>
  //         <td>${data.data.type}</td>
  //         <td>${data.data.factura}</td>
  //         <td>${data.data.date}</td>
  //         <td>${data.data.cash_n}</td>
  //         <td>${data.data.check_n}</td>
  //         <td>${data.data.sum_tng}</td>
  //         <td>${data.data.status}</td>
  //         <td>
  //           <a href="/receipt/${data.data.id}/pdf" target="_blank" id="getCheck-{{ $invoice->id }}" class="btn-sm">
  //               <img src="https://small.kz/images/icons/invoice_receipt.png" alt="">
  //           </a>
  //           <a href="/waybill/${data.data.id}/pdf" target="_blank" id="getCheck-{{ $invoice->id }}" class="btn-sm">
  //               <img src="https://small.kz/images/icons/invoice_waybill.png" alt="">
  //           </a>
  //         </td>
  //       </tr>`);
  //     }
  //     el.after($(`
  //     <div class='result-message alert alert-${data.type} alert-block'>
  //       <strong>${data.message}</strong>
  //     </div>`));
  //   })
  //   .fail((err) => {
  //     $('.loading').remove();
  //     onError(err);
  //   });
  // });

  //  receipt OLD code
  $('#receiptResult').on('submit', (e) => {
    e.preventDefault();
    e.stopPropagation();

    $('.loading').remove();
    $('.result-message').remove();
    $('.errorMessage').remove();

    const form = $('#receiptResult').serialize();

    const el = $('#receiptMaxDate');
    el.after($('<span class="loading">Загрузка...</span>'));
    const btn = document.getElementById('oldSubmitBtn');
    btn.setAttribute('disabled', true);
    btn.style.backgroundColor = 'grey';

    $.ajax({
      url: $('#receiptResult').attr('action'),
      data: form,
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
    .done((data) => {
      btn.removeAttribute('disabled');
      btn.style.backgroundColor = 'red';

      $('.loading').remove();
      if (data.type === 'success') {
        $('#receiptStep3').toggleClass('receiptRegister__step--active');
        $('#receiptStep2Old').toggleClass('receiptRegister__step--deactive');
        $('#stepStatus2').removeClass('blockSteps__step--active');
        $('#stepStatus3').toggleClass('blockSteps__step--active');
        $('#receiptsTable tr:last')
          .after(`
          <tr>
            <td>${data.data.stock.title}</td>
            <td>${data.data.cash_number}</td>
            <td>${data.data.sell_number}</td>
            <td>${data.data.sum}</td>
            <td>${data.data.buy_date}</td>
          </tr>`);
      }
      el.after($(`
      <div class='result-message alert alert-${data.type} alert-block'>
        <strong>${data.message}</strong>
      </div>`));
    })
    .fail((err) => {
      onError(err);
      btn.removeAttribute('disabled');
      btn.style.backgroundColor = 'red';
      $('.loading').remove();
    });
  });

  // new Format receipt registration - Регистрация чеков по новому формату
  $('#newTypeReceiptForm').on('submit', (e) => {
    e.preventDefault();
    e.stopPropagation();

    $('.loading').remove();
    $('.result-message').remove();
    $('.errorMessage').remove();
    const form = $('#newTypeReceiptForm').serialize();

    const el = $(document).find('#quField');
    el.after($('<div style="margin-top: 1vw;">'
        + '<span class="loading">Загрузка...</span>'
    + '</div>'));

    const btn = document.getElementById('submitBtn');
    btn.setAttribute('disabled', true);
    btn.style.backgroundColor = 'grey';

    $.ajax({
      url: $('#newTypeReceiptForm').attr('action'),
      data: form,
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: 'POST',
      dataType: 'JSON',
    })
    .done((data) => {
      btn.removeAttribute('disabled');
      btn.style.backgroundColor = 'red';
      $('.loading').remove();
      if (data.type === 'success') {
        $('#receiptStep3').toggleClass('receiptRegister__step--active');
        $('#receiptStep2New').toggleClass('receiptRegister__step--deactive');
        $('#stepStatus2').removeClass('blockSteps__step--active');
        $('#stepStatus3').toggleClass('blockSteps__step--active');
        $('#receiptsTable tr:last')
        .after(`
          <tr>
            <td>${data.data.stock.title}</td>
            <td>${data.data.cash_number}</td>
            <td>${data.data.sell_number}</td>
            <td>${data.data.sum}</td>
            <td>${data.data.buy_date}</td>
          </tr>`);
      }

      el.after($(`
      <div class='result-message alert alert-${data.type} alert-block'>
        <strong>${data.message}</strong>
      </div>`));
    })
    .fail((err) => {
      onError(err);
      btn.removeAttribute('disabled');
      btn.style.backgroundColor = 'red';
      $('.loading').remove();
    });
  });
});
