function load() {
    $.magnificPopup.open({
        items: {
            src: '#city-modal'
        },
        type: 'inline'
    });
}

// function getLocation() {
//   // Если геолокация поддерживается браузером
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//     localStorage.setItem("getLocation", true);
//   } else {
//     console.log("Геолокация не поддерживается.");
//   };
  
// };

if (!localStorage.getItem('firstSession')) {
  
  //document.addEventListener('DOMContentLoaded', getLocation);
  localStorage.setItem("firstSession", 1);

} else {

  if (!localStorage.getItem('cityModal') && !localStorage.getItem('getLocation')) {
    document.addEventListener('DOMContentLoaded', load);
    localStorage.setItem("cityModal", true);
  };
};

/*!localStorage.getItem('city') && */