import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

function Attachment({model_type, model_id, action}) {
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    fetch(`/attachment?model_type=${model_type}&model_id=${model_id}`)
      .then(response => response.json())
      .then(result => {
        setAttachments(result);
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  }, []);

  function deleteAttachment(attachment_id) {
    const findAttachment = attachments.findIndex(({id}) => id === attachment_id);

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const formData = new FormData();
    formData.append('id', attachment_id);

    fetch('/attachment/delete', {
      headers: {
        "X-CSRF-TOKEN": token,
      },
      method: 'post',
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        if (findAttachment !== -1) {
          const newAttachments = [
            ...attachments.slice(0, findAttachment),
            ...attachments.slice(findAttachment + 1),
          ];
          setAttachments(newAttachments);
        }
      });
  }

  return (
    <div>
      <hr />
      <div className="attachment-uploader">
        {attachments.length > 0 && (
          <strong>Прикрепленные файлы</strong>
        )}
        {attachments.map((attachment) => (
          <div key={attachment.id} className="attachment-block">
            <a href={`/download-attachment/${attachment.id}`}>{attachment.name}</a>
            {(parseInt(action, 10) === 2
              || parseInt(action, 10) === 3) && ( // 2 means attachment uploading
              <a onClick={() => deleteAttachment(attachment.id)} className="delete">x</a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Attachment;

const attachmentComponent = document.getElementById('AttachmentComponent');
if (attachmentComponent) {
    ReactDOM.render(<Attachment
        model_id={attachmentComponent.getAttribute('model-id')}
        model_type={attachmentComponent.getAttribute('model-type')}
        action={attachmentComponent.getAttribute('action')}
    />, document.getElementById('AttachmentComponent'));
}
