import $ from 'jquery';
import moment from 'moment';
import Plyr from 'plyr';
import 'jscroll';

// const player = new Plyr('video');
// window.player = player;

$('.game-start').click(function () {
  const customEvent = new Event('startIntDevelsGame');
  window.dispatchEvent(customEvent);
});

$('.game-start-standalone').click(function () {
  const customEvent = new Event('startIntDevelsGameStandalone');
  window.dispatchEvent(customEvent);
});

$("#jobForm").submit(function(e) {

    $('#click-once').click().prop('disabled',true);
    event.preventDefault();

    const nameInput = document.getElementById('formName').value;
    const phoneInput = document.getElementById('formPhone').value;
    const citySelect = document.getElementById('formCitySelect').value;
    const positionSelect = document.getElementById('formPositionSelect').value;
    const fileInput = document.getElementById('fileInput').files[0];
    const formCheckBox = document.getElementById('formCheckBox').checked;
    const successMessageBox = document.getElementById('successMessageBox');
    const jobForm = document.getElementById('jobForm');

    if (formCheckBox) {
      const formData = new FormData();
      formData.append('fio', nameInput);
      formData.append('phone', phoneInput);
      formData.append('city_id', citySelect);
      formData.append('position_id', positionSelect);
      formData.append('is_approved', formCheckBox);
      formData.append('file', fileInput);

      const sendingData = {
        fio: nameInput,
        phone: phoneInput,
        city_id: citySelect,
        position_id: positionSelect,
        is_approved: formCheckBox
      };

      if (fileInput) {
        console.log("asdasd");
        axios.post('/resume', formData)
          .then((e) => {
            $('#jobForm').hide();
            $(".successBox").addClass("successBox--active");
          }).catch(e => {
          console.log('error', e);
        })
      } else {
        alert('Добавьте файл');
        $('#click-once').click().prop('disabled',false);
      }
    } else {
      alert('Подтвердите что согласны с условиями Политики конфиденциальности');
      $('#click-once').click().prop('disabled',false);
    }
});

$("#faqForm").submit(function (e) {
  $('#click-once').click().prop('disabled',true);
  e.preventDefault(); //To not refresh the page
  let formData = new FormData(this);
  $.each($("#fileInputMultiple"), function(i, file) {
    formData.append('file['+i+']', file.files[0]);
  });
  $.ajax({
    url: $('#faqForm').attr('action'),
    type: 'POST',
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    data: formData,
    contentType: false,
    processData: false,
    success: function()
    {
      $('#faqForm').hide();
      $(".successBox").addClass("successBox--active");
    },
    error: function()
    {
      console.log('error');
    }
  });
});

// disable multiple click submit
$('#edit-company-form').submit(function() {
    $('#click-once').click().prop('disabled',true);
});

$('#add-company-form').submit(function() {
    $('#click-once').click().prop('disabled',true);
});

$(document).ready(() => {

  $('.phoneNumber').inputmask('+9 (999) 999-99-99');
  $('#formPhone').inputmask('+9 (999) 999-99-99');

  const controls = [
      'play-large', // The large play button in the center
      // 'restart', // Restart playback
      'rewind', // Rewind by the seek time (default 10 seconds)
      'play', // Play/pause playback
      'fast-forward', // Fast forward by the seek time (default 10 seconds)
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'mute', // Toggle mute
      'volume', // Volume control
      // 'captions', // Toggle captions
      // 'settings', // Settings menu
      // 'pip', // Picture-in-picture (currently Safari only)
      // 'airplay', // Airplay (currently Safari only)
      // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
      'fullscreen', // Toggle fullscreen
    ];

  const players = Plyr.setup('.js-player', { controls });

  $('.inputfile').change(function (e) {
    const fileName = e.target.files[0].name;
    console.log(fileName);
    $(this).next().children('span').text(`${lang.messages.selected_file } ${ fileName}`);
  });
  $('.winnersHead').click(function () {
      $('.winnersBody').slideUp();
      $(this).next().stop().slideToggle();
      $(this).children('.winnersHead__icon').toggleClass('winnersHead__icon--active');
      $('.winnersHead').not($(this)).children('.winnersHead__icon').removeClass('winnersHead__icon--active');
  });
  $('.faqTitle').click(function () {
    $('.faqAnswers').slideUp();
    $(this).next().stop().slideToggle();
  });
  $('.shopAddressTitle').click(function () {
    $(this).next().stop().slideToggle();
  });
  $('.advertTitle').click(function () {
    $('.advertAnswers').slideUp();
    $(this).next().stop().slideToggle();
  });
  $('.dropdown-nav a').click(function () {
    $('.dropdown').slideUp();
    $(this).next().stop().slideToggle();
  });
  $('main').click(() => {
    $('.dropdown').slideUp();
  });

  $('.alert-close').click(function () {
    $(this).parent('.alert').remove();
  });
  $('.goodsFilterTitle').click(function () {
    $(this).next().stop().slideToggle();
  });

  $('.promoCatalog__btnMore').click(function () {
    $('.promoCatalog__more').slideToggle();

    if ($(this).text() == 'Смотреть все') {
      $(this).text('Скрыть');
    } else {
      $(this).text('Смотреть все');
    }
  });

  $('.accordionItem__heading').click(function () {
    $('.accordionItem__content').slideUp();
    $(this).toggleClass('accordionItem__heading--active');
    $(this).next().stop().slideToggle();
    $('.accordionItem__heading').not($(this)).removeClass('accordionItem__heading--active');
  });

  $('.esf-law-toggle').click(() => {
    $('.esfLawInfo').slideToggle();
  });


  $('.goodsCatalogBtn').click(function () {
       $(".goodsCatalogBodyLeft").toggleClass('shrink');
      $(".goodsCatalogBodyLeft").toggle("slide");
      $(".goodsCatalogBodyRight").toggleClass('full');

      if ($(this).text() == 'Скрыть фильтры') {
        $(this).text('Показать фильтры');
      } else {
        $(this).text('Скрыть фильтры');
      }
  });


  $(document).on('click', '.esfTable__btn', (e) => {
    e.preventDefault();
    const pos = $(this).offset();
    const id = e.target.getAttribute('href');
    if ($(`#${id}`).hasClass('esfTable__action--show')) {
      $('.esfTable__action').removeClass('esfTable__action--show');
    } else {
      $('.esfTable__action').removeClass('esfTable__action--show');
      $(`#${id}`).addClass('esfTable__action--show');
      $(`#${id}`).offset(pos);
      $(`#${id}`).addClass('esfTable__action--pos');
    }
  });

  $('.newsList').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.blockNewsSlider').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.mainRecipes').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.stockSlick').slick({
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });

  $('.jobSlider').slick({
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    variableWidth: true,

    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  });



    $('.recipeSingleSlider').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  });


  $('.makeSlider').slick({
    dots: false,
    mobileFirst: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 580,
        settings: 'unslick',
      },
    ],
  });


  $('.rentGalleryMobile').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 580,
        settings: 'unslick',
      },
    ],
  });


  $('.promoBrandsGallery').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 580,
        settings: 'unslick',
      },
    ],
  });

 $('.recipeOthersBox').slick({
    dots: false,
    mobileFirst: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 580,
        settings: 'unslick',
      },
    ],
  });


 $('.charitySliderDesc').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  draggable: false,
  // asNavFor: '.charitySliderOrganizations'

    responsive: [
      {
        breakpoint: 780,
        settings: {
          adaptiveHeight: true,
          swipe: false,
        },
      },
    ],

});

$('.charitySlider').slick({
  slidesToShow: 10,
  slidesToScroll: 1,
  speed: 400,
  cssEase: 'linear',
  variableWidth: true,
  asNavFor: '.charitySliderDesc',
  arrows: false,
  // centerMode: true,
  focusOnSelect: true,
});

$('.bannerSlider').slick({
  dots: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
  ],
});

$('.readOther').slick({
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
  ],
});

// $('.bannerSlider').slick({
//   centerMode: true,
//   slidesToShow: 2,
//   slidesToScroll: 1,
//   speed: 400,
//   cssEase: 'linear',
//   variableWidth: true,
//   asNavFor: '.bannerSliderDesc',
//   focusOnSelect: true,
// });
//
// $('.bannerSliderDesc').slick({
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
//   fade: true,
//   draggable: false,
//
//   responsive: [
//     {
//       breakpoint: 780,
//       settings: {
//         adaptiveHeight: true,
//         swipe: false,
//       },
//     },
//   ],
// });


  // $('.massMediaItems').slick({
  //   dots: false,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   prevArrow: '<img src="/assets/icons/arrowl.png" class="slick-prev">',
  //   nextArrow: '<img src="/assets/icons/arrowr.png" class="slick-next">',
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // });

  $('.banners').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: '<img src="/assets/icons/arrowl.png" class="slick-prev">',
    nextArrow: '<img src="/assets/icons/arrowr.png" class="slick-next">',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
        },
      },
    ],
  });

  $('.bannersFull').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    mobileFirst: true,
    speed: 300,
    useTransform: true,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '20px',
          variableWidth: true,
        },
      },
    ],
  });

  $('.catalogItems').slick({
    dots: true,
    arrows: true,
    infinite: false,
    prevArrow: '<img src="/assets/icons/arrowl.png" class="slick-prev">',
    nextArrow: '<img src="/assets/icons/arrowr.png" class="slick-next">',
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.promoGifts__wrap--mars2').slick({
    dots: false,
    mobileFirst: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    variableWidth: false,
    centerMode: true,
    prevArrow: '<img src="/../../images/icons/arrow-slider-left.svg" class="promoGifts__marsArrowLeft">',
    nextArrow: '<img src="/../../images/icons/arrow-slider-right.svg" class="promoGifts__marsArrowRight">',

    responsive: [
      {
        breakpoint: 1240,
        settings: 'unslick',
      },
    ],
  });

  $('.promoGifts__wrap--rastishka').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    variableWidth: true,
    centerMode: true,
    prevArrow: '<img src="/../../images/promo/rastishka/icons/arrow-slider-left.png" class="promoGifts__rastishkaArrowLeft">',
    nextArrow: '<img src="/../../images/promo/rastishka/icons/arrow-slider-right.png" class="promoGifts__rastishkaArrowRight">',

    responsive: [
      {
        breakpoint: 1240,
        settings: 'unslick',
      },
    ],
  });

  $('.promoGifts__wrap--fruto').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    variableWidth: true,
    centerMode: true,
    prevArrow: '<img src="/../../images/promo/fruto/icons/arrow-left.png" class="promoGifts__rastishkaArrowLeft">',
    nextArrow: '<img src="/../../images/promo/fruto/icons/arrow-right.png" class="promoGifts__rastishkaArrowRight">',

    responsive: [
      {
        breakpoint: 1240,
        settings: 'unslick',
      },
    ],
  });

  $('.promoGifts__wrap--coca').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    variableWidth: true,
    centerMode: true,
    prevArrow: '<img src="/../../images/promo/coca-cola-new/coca-slider-arrow-left.svg" class="promoGifts__rastishkaArrowLeft">',
    nextArrow: '<img src="/../../images/promo/coca-cola-new/coca-slider-arrow-right.svg" class="promoGifts__rastishkaArrowRight">',

    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick',
      },
    ],
  });





  $('.evolutionProductModal__preview').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    centerMode: true,
    centerPadding: '20',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          //centerPadding: '20px',
          variableWidth: true,
        },
      },
    ],
  });

  $('.promoTicker__slider').slick({
    dots: false,
    mobileFirst: true,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    //autoplay: false,
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 1000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    //cssEase: 'linear',
    variableWidth: true,
    //centerMode: true,
    centerPadding: '20',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
    ],
  });



$('.evProducts__slider').slick({
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  variableWidth: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
  ],
});

$('.evProducts__slider').on('init', function(event, slick, currentSlide) {
  var current = (currentSlide ? currentSlide : 0);
  if (current == 0) {
    $('.prev').click(function() {
      $('.evProducts__slider').slick('slickGoTo', (slick.slideCount - 1));
    })
  }
});

$('.evProducts__slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
  if ((slick.slideCount - 1) == currentSlide) {
    $('.next').click(function() {
      $('.evProducts__slider').slick('slickGoTo', 0);
    })
  }
});


  $('.minMenuSign').click('click', () => {
      $('.minMenu').addClass('minMenuToggle');
      $('.overlay').show();
  });

  $('.mobileMenuBurger').click('click', () => {
      $('.minMenu').addClass('minMenuToggle');
      $('.overlay').show();
  });

  $('.close-mobile-menu').click('click', () => {
      $('.minMenu').removeClass('minMenuToggle');
      $('.overlay').hide();
  });

  $('.mobileFilterBtn').click('click', () => {
    if ($('.asideFilters').hasClass('filtersShow')) {
      $('.asideFilters').removeClass('filtersShow');
    } else {
      $('.asideFilters').addClass('filtersShow');
    }
  });

  $('.closeMobileFilter').click('click', () => {
    if ($('.asideFilters').hasClass('filtersShow')) {
      $('.asideFilters').removeClass('filtersShow');
    } else {
      $('.asideFilters').addClass('filtersShow');
    }
  });

  $('.overlay').on('click', () => {
    $('.minMenu').removeClass('minMenuToggle');
    $('.first').removeClass('minMenuStickFirst');
    $('.middle').removeClass('minMenuStickMiddle');
    $('.last').removeClass('minMenuStickLast');
    $('.overlay').hide();
  });


  // Job Mission Page Competence block Mobile

  if ($(window).width() < 1000) {
    $('.blockCompetence').click(function () {
       $(this).toggleClass('blockCompetence-show');
    });
  }

  // Custom input filde Drag and Drop

  // show where is the drop-zone:
  $('#dropContainer').on('dragenter', function () {
    this.classList.add('dragged-over');
  })
   .on('dragend drop dragexit dragleave', function () {
    this.classList.remove('dragged-over');
  });

  // display file name to label
  $('#oldFileInput').change(function () {
    const i = $(this).prev('label').clone();
    const file = $('#oldFileInput')[0].files[0].name;
    $(this).prev('label').text(file);
  });

   $('#fileInput').change(function () {
    const i = $(this).prev('label').clone();
    const file = $('#fileInput')[0].files[0].name;
    $(this).prev('label').text(file);
  });

    // ESF scripts
   // Receipt Forms

  $('.contactForm__toggle').next('.contactForm__toggleBox').slideUp();
  $('.contactForm__toggle--active').next('.contactForm__toggleBox').slideDown();


  $('.contactForm__toggle').click(function () {
    event.preventDefault();

    if ($(this).hasClass('contactForm__toggle--active')) {
      $(this).toggleClass('contactForm__toggle--active').next('.contactForm__toggleBox').slideUp();
    } else {
      $(this).toggleClass('contactForm__toggle--active').next('.contactForm__toggleBox').slideDown();
    }

    if ($(this).parents('#receiptResult').length) {
      $('#newTypeReceiptForm .contactForm__toggle').removeClass('contactForm__toggle--active').next('.contactForm__toggleBox').slideUp();
    } else {
      $('#receiptResult .contactForm__toggle').removeClass('contactForm__toggle--active').next('.contactForm__toggleBox').slideUp();
    }
  });

  // $('#checkReceiptType').inputmask({regex: '^[0-9]{0,}[- _;,.][0-9]{0,}[- _;,.][0-9]{0,}[- _;,.][0-9]{0,}$', placeholder: ''});
  $('#FieldNumberFirst').inputmask('999', { oncomplete() { $('#FieldNumberSecond').focus(); } });
  $('#FieldNumberSecond').inputmask('9999', { oncomplete() { $('#FieldNumberThird').focus(); } });
  $('#FieldNumberThird').inputmask('999999', { oncomplete() { $('#FieldNumberFourth').focus(); } });
  $('#FieldNumberFourth').inputmask('9999');


  // Receipt Steps

  $('.receiptRegister__btn').click(function () {
    event.preventDefault();

    if ($(this).hasClass('btn--newType')) {
      $('#receiptStep2New').toggleClass('receiptRegister__step--active');
      $('#receiptStep1').toggleClass('receiptRegister__step--deactive');
      $('#stepStatus1').removeClass('blockSteps__step--active').next('.blockSteps__step').toggleClass('blockSteps__step--active');
    } else {
      $('#receiptStep2Old').toggleClass('receiptRegister__step--active');
      $('#receiptStep1').toggleClass('receiptRegister__step--deactive');
      $('#stepStatus1').removeClass('blockSteps__step--active').next('.blockSteps__step').toggleClass('blockSteps__step--active');
    }
  });

  $('.contactForm__agreement--esf').click(function () {

     if ($('.contactForm__checkboxESF').is(':checked')) {
        $('.contactForm__btn--esf').removeClass('contactForm__btn--disabled').removeAttr("disabled");
        $('.contactForm__btn--esf').css('background-color','#FF1F1F');
      } else {
        $('.contactForm__btn--esf').addClass('contactForm__btn--disabled').attr("disabled", true);
      }
  });

 $('.contactForm__instruction').magnificPopup({
    type: 'inline',
    showCloseBtn: 'false',
    fixedContentPos: 'false',
  });

  $('.game-start').magnificPopup({
    type: 'inline',
    showCloseBtn: 'false',
    fixedContentPos: 'false',
  });

  $('.game-start-standalone').magnificPopup({
    type: 'inline',
    showCloseBtn: 'false',
    fixedContentPos: 'false',
  });  


  $('.mfp-close').click(function () {
console.log('TEST BUTTON CLOSE');
  });

$('.game-close').click(() => {
    $.magnificPopup.close();
    $('.intdevels-game-css').remove();
});


$('.receiptFormPopup__close').click(() => {
    $.magnificPopup.close();
});

  // Compliance Apply

  $('#applyCustomSelect .select-items div').click(function () {
     if ($('#selectApplyAnonym').is(':selected')) {
        $('#formName').slideUp();
      } else {
         $('#formName').slideDown();
      }
  });





  $('.popup-with-move-anim').magnificPopup({
    type: 'inline',

    fixedContentPos: false,
    fixedBgPos: true,

    overflowY: 'auto',

    closeOnBgClick: false,

    closeBtnInside: true,
    preloader: false,

    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-slide-bottom',
  });

  $('.langItem').click(function () {
    $(this).hide();
    $('.langHide').css({
      left: 0,
    });
  });

  $("input[type='date']").attr({
    max: moment().format('YYYY-MM-DD'),
    min: moment().subtract(12, 'days').format('YYYY-MM-DD'),
    value: moment().format('YYYY-MM-DD'),
  });

  $('.goodsFilterOptions').click(() => {
    if ($(window).width() > 575) {
      setTimeout(() => {
        $('.goodsFilters').submit();
      }, 2000);
    }
  });

  $('.mobileSearch').click(() => {
    $('.searchToggle').slideToggle('show');
    $('.searchToggleMobile').slideToggle('show');
  });

  $('.subscribeCheckbox').click(() => {
    $('#subscribeBtn').prop('disabled', false).addClass('btn-danger');
  });

  // if ($("input[name='non_resident']").prop('checked')) {
  //   $("input[name='company_bin']").hide();
  //   $("input[name='consignee_bin']").hide();
  //   $("input[name='company_bin']").attr('disabled', true).val('');
  //   $("input[name='consignee_bin']").attr('disabled', true).val('');
  // }
  //
  // $("input[name='non_resident']").click(function () {
  //   if ($("input[name='non_resident']").prop('checked')) {
  //     $("input[name='company_bin']").hide();
  //     $("input[name='consignee_bin']").hide();
  //   } else {
  //     $("input[name='company_bin']").show();
  //     $("input[name='consignee_bin']").show();
  //   }
  //
  //   $("input[name='company_bin']").attr('disabled', $(this).prop('checked')).val('');
  //   $("input[name='consignee_bin']").attr('disabled', $(this).prop('checked')).val('');
  // });

  // $('ul.pagination').hide();
  $(() => {
    $('.infinite-scroll').jscroll({
      autoTrigger: true,
      loadingHtml: '<img class="loading" src="/images/icons/loading.gif" alt="Loading..." />',
      padding: 0,
      nextSelector: '.pagination li.active + li a',
      contentSelector: 'div.goods',
      callback() {
        $('ul.pagination').remove();
      },
    });
  });

  // $(() => {
  //   $('.infinite-scroll').jscroll({
  //     autoTrigger: true,
  //     loadingHtml: '<img class="loading" src="/images/icons/loading.gif" alt="Loading..." />',
  //     padding: 0,
  //     nextSelector: '.pagination li.active + li a',
  //     contentSelector: 'div.newsOwn',
  //     callback() {
  //       $('ul.pagination').remove();
  //     },
  //   });
  // });

  const directionsArray = ['#officeDirection', '#shopDirection', '#stockDirection', '#productionDirection', '#expatDirection'];

  directionsArray.forEach((e) => {
    $(e).on({
      mouseover() {
        if ($(window).width() > 1000) {
          $(this).css('background', `url("../../assets/company/${e.substr(1)}Main.png")`);
          $(this).children('.directionLabel').css('background-color', '#C4C4C4');
          $(this).css('transform', 'scale(1.1)');
          $(`${e} .directionLabel p`).css('margin', '5px 0');
          $(`${e} .directionInfoBtn`).css('display', 'block');
        }
      },
      mouseout() {
        if ($(window).width() > 1000) {
          $(this).css('background', `url("../../assets/company/${e.substr(1)}Preview.png")`);
          $(this).children('.directionLabel')
            .css('background-color', 'inherit');
          $(this).css('transform', '');
          $(`${e} .directionLabel p`).css('margin', '30px 0');
          $(`${e} .directionInfoBtn`).hide();
        }
      },
    });
  });

  const valuesArray = ['#respectValue', '#efficiencyValue', '#developmentValue', '#professionalTeamValue', '#honestyValue'];

  valuesArray.forEach((e) => {
    $(e)
      .on({
        mouseover() {
          $(this).attr('src', `/images/mission/${e.substr(1)}.gif`);
        },
      });
  });

  let i = 2;
  let clicked = false;

  const shopCareerFeatures = ['#shopCareerFeature1', '#shopCareerFeature2', '#shopCareerFeature3', '#shopCareerFeature4', '#shopCareerFeature5'];

  shopCareerFeatures.forEach((e) => {
    $(e).click(function () {
      $('.careerFeatures>a.active').removeClass('active');
      $(this).addClass('active');
      $('#shopCareerFeatureImg').attr('src', `/images/company/shops/banner${e.slice(-1)}.png`);
      clicked = true;
    });
  });

  const prodCareerFeatures = ['#prodCareerFeature1', '#prodCareerFeature2', '#prodCareerFeature3', '#prodCareerFeature4', '#prodCareerFeature5'];

  prodCareerFeatures.forEach((e) => {
    $(e).click(function () {
      $('.careerFeatures>a.active').removeClass('active');
      $(this).addClass('active');
      $('#prodCareerFeatureImg').attr('src', `/images/company/own-production/banner${e.slice(-1)}.png`);
      clicked = true;
    });
  });

  const officeCareerFeatures = ['#officeCareerFeature1', '#officeCareerFeature2', '#officeCareerFeature3', '#officeCareerFeature4', '#officeCareerFeature5'];

  officeCareerFeatures.forEach((e) => {
    $(e).click(function () {
      $('.careerFeatures>a.active').removeClass('active');
      $(this).addClass('active');
      $('#officeCareerFeatureImg').attr('src', `/images/company/office/banner${e.slice(-1)}.png`);
      clicked = true;
    });
  });

  const stockCareerFeatures = ['#stockCareerFeature1', '#stockCareerFeature2', '#stockCareerFeature3', '#stockCareerFeature4', '#stockCareerFeature5'];

  stockCareerFeatures.forEach((e) => {
    $(e).click(function () {
      $('.careerFeatures>a.active').removeClass('active');
      $(this).addClass('active');
      $('#stockCareerFeatureImg').attr('src', `/images/company/stock/banner${e.slice(-1)}.png`);
      clicked = true;
    });
  });

  function loop(value1, value2) {
    setTimeout(() => {
      if (!clicked) {
        $('.careerFeatures>a.active').removeClass('active');

        $(`#${value1}CareerFeature${i}`).addClass('active');
        $(`#${value1}CareerFeatureImg`).attr('src', `/images/company/${value2}/banner${i}.png`);

        if (i === 5) {
          i = 1;
        } else {
          i += 1;
        }
        loop(value1, value2);
      }
    }, 3000);
  }

  // $('.flurrySnow').flurry({
  //   character: '❄',
  //   color: 'white',
  //   frequency: 160,
  //   speed: 20000,
  //   small: 10,
  //   large: 40,
  //   wind: 40,
  //   windVariance: 50,
  //   rotation: 90,
  //   rotationVariance: 180,
  //   startOpacity: 1,
  //   endOpacity: 0,
  //   opacityEasing: 'cubic-bezier(1,.3,.6,.74)',
  //   blur: true,
  //   overflow: 'hidden',
  //   zIndex: 9999,
  // });









  if (window.location.href.includes('company/shops') && !clicked) {
    loop('shop', 'shops');
  } else if (window.location.href.includes('company/office') && !clicked) {
    loop('office', 'office');
  } else if (window.location.href.includes('company/own-production') && !clicked) {
    loop('prod', 'own-production');
  } else if (window.location.href.includes('company/stock') && !clicked) {
    loop('stock', 'stock');
  }

  $('.seeMoreBtn').click(() => {
    $('.seeMore').hide();
    $('.activateMore').toggle();
  });

  let geoDiagramEvent = false;
  const geoDiagram = $('.geoDiagram').offset().top;

  $(window).on('scroll', () => {
    const currentPosition = $(document).scrollTop();
    if (currentPosition > geoDiagram && geoDiagramEvent === false) {
      geoDiagramEvent = true;
      $('.count').each(function () {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text(),
        }, {
          duration: 3000,
          easing: 'swing',
          step(now) {
            $(this).text(Math.ceil(now));
          },
        });
      });
    }
  });
});

