import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

function InvoiceDetailModal({ cash, check, sum_tng, item_id }) {
  const [items, setItems] = useState([]);
  let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  console.log('test');
  function loadData() {
    axios.post('/api/invoice', { cash, check, sum_tng })
      .then((r) => {
        setItems(r.data);
      }).catch((er) => console.warn(er));
  }

  return (
    <div id={`check-modal`} className="mfp-hide white-popup-block zoom-anim-dialog">
      {items}
    </div>
  );
}

export default InvoiceDetailModal;

const invoiceDetail = document.getElementById('InvoiceDetailModal');
if (document.getElementById('InvoiceDetailModal')) {
  ReactDOM.render(<InvoiceDetailModal
    cash={invoiceDetail.getAttribute('data-cash')}
    check={invoiceDetail.getAttribute('data-check')}
    sum_tng={invoiceDetail.getAttribute('data-sum_tng')}
    item_id={invoiceDetail.getAttribute('data-id')}
  />, document.getElementById('InvoiceDetailModal'));
}
