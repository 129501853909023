import $ from 'jquery';

$(document).on('submit', '#oldEsfForm', (e) => {
  $('#oldSubmitBtn').attr('disabled', 'disabled');
  $('#oldSubmitBtn').css('backgroundColor', '#A2A2A2');
  e.preventDefault(); e.stopPropagation();
  const formData = new FormData(e.target);
  if ($('#fileInput').val()) {
    formData.append('pa_file', $('#fileInput')[0].files[0]);
  }
  const el = $(document).find('#receiptMaxDate');
  $.ajax({
    url: $('#oldEsfForm').attr('action'),
    data: formData,
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    type: 'POST',
    dataType: 'JSON',
    processData: false,
    contentType: false,
    success(r) {
      console.log('esf result', r);
      if (r.status === 'error_message' || r.status === 'esf_receipt_alco_checked') {
          console.log('status', r.status);
        el.after(
          $(`
            <div class='result-message alert alert-${r.type} alert-block'>
              <strong>${r.message}</strong>
            </div>
          `),
        );
      } else if (r.status === 'success') {
        $('#receiptStep3').toggleClass('receiptRegister__step--active');
        $('#receiptStep2Old').toggleClass('receiptRegister__step--deactive');
        $('#stepStatus2').removeClass('blockSteps__step--active');
        $('#stepStatus3').toggleClass('blockSteps__step--active');
      }
      $('#oldSubmitBtn').removeAttr('disabled');
      $('#oldSubmitBtn').css('backgroundColor', '#EA0000');
    },
    error(r) {
      const errorMessage = JSON.parse(r.responseText);
      console.log('errorMessage',errorMessage);
        $('.esf-validate-message').remove();
        $('.input-field').removeAttr('style');
      if (errorMessage) {
        for (const k in errorMessage) {
          $(`.oldEsf-${k}`).css('borderColor', '#dc3545');
          $(`.oldEsf-${k}`).after(`<span class="esf-validate-message" style="color: #dc3545">${errorMessage[k][0]}</span>`);
        }
      }
      $('#oldSubmitBtn').removeAttr('disabled');
      $('#oldSubmitBtn').css('backgroundColor', '#EA0000');
    },
  });
});

