import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

function Image({model_type, model_id, action}) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch(`/image?model_type=${model_type}&model_id=${model_id}`)
      .then(response => response.json())
      .then(result => {
        setImages(result);
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  }, []);

  function deleteImage(image_id) {
    const findImage = images.findIndex(({id}) => id === image_id);

    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const formData = new FormData();
    formData.append('id', image_id);

    fetch('/image/delete', {
      headers: {
        "X-CSRF-TOKEN": token,
      },
      method: 'post',
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        if (findImage !== -1) {
          const newImages = [
            ...images.slice(0, findImage),
            ...images.slice(findImage + 1),
          ];
          setImages(newImages);
        }
      });
  }

  return (
    <div>
      <div className="image-uploader">
        {images.length > 0 && (
          <strong>Прикрепленные изображения</strong>
        )}
        <div className="images-list">
          {images.map((image) => (
            <div key={image.id} className="image-block">
              <a href={`/download-image/${image.id}`}>
                <img src={image.path} />
              </a>
              {(parseInt(action, 10) === 2
                || parseInt(action, 10) === 3) && ( // 2 means image uploading
                <a onClick={() => deleteImage(image.id)} className="delete">x</a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Image;

const imageComponent = document.getElementsByClassName('ImageComponent');
for (const element of imageComponent) {
  if (element) {
    ReactDOM.render(<Image
      model_id={element.getAttribute('model-id')}
      model_type={element.getAttribute('model-type')}
      action={element.getAttribute('action')}
    />, element);
  }
}
