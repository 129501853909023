import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';

function PhoneMask() {
  const [phone, setPhone] = useState('');

  return (
    <InputMask
      name="phone"
      type="tel"
      required
      className="form-control"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      mask="+7\(999) 999-99-99"
      maskChar=" "
      alwaysShowMask
    />
  );
}

export default PhoneMask;

const phoneMaskComponent = document.getElementsByClassName('inputMask');
for (const element of phoneMaskComponent) {
    if (element) {
        ReactDOM.render(<PhoneMask />, element);
    }
}
