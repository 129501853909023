const dt = new DataTransfer(); // Allows you to manipulate the files of the input file

$("#fileInputMultiple").on('change', function(e){
	for(var i = 0; i < this.files.length; i++){
		let fileBloc = $('<span/>', {class: 'file-block'}),
			 fileName = $('<span/>', {class: 'name', text: this.files.item(i).name});
		fileBloc.append('<span class="file-delete"></span>')
			.append(fileName);
		$("#filesList > #filesNames").append(fileBloc);
		$(".contactForm__file p").text('Файлов добавлено: ' + (i+1));
	};
	// Adding files to the DataTransfer object
	for (let file of this.files) {
		dt.items.add(file);
	}
	// Update of the files of the input file after addition
	this.files = dt.files;
	$(".contactForm__file p").text('Файлов добавлено: ' + (dt.items.length));

	// EventListener for delete button created
	$('span.file-delete').click(function(){
		let name = $(this).next('span.name').text();
		// Suppress file name display
		$(this).parent().remove();
		for(let i = 0; i < dt.items.length; i++){
			// Match file and name
			if(name === dt.items[i].getAsFile().name){
				// Deleting file in DataTransfer object
				dt.items.remove(i);
				continue;
			}
		}
		// Updating input file files after deletion
		document.getElementById('fileInputMultiple').files = dt.files;
		$(".contactForm__file p").text('Файлов добавлено: ' + (dt.items.length));
	});
});