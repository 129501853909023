import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import SortableTree from 'react-sortable-tree';

import Notifications, { notify } from 'react-notify-toast';
import axios from 'axios';

function KitchenList() {
//   const [kitchen, setKithen] = useState([]);
//   let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

//   useEffect(() => {
//     fetch('cabinet/kitchen-vacancies')
//       .then((response) => {
//         console.log(response, 'resp')
//         })
 
//   }, []);
// function loadData(){
//     axios.get('/kitchen-vacancies').then((r)=>{console.log(r,'gfg')})
// }



 

  return (
    <>
    <div>hello</div>
    </>
  );
}

export default KitchenList;

if (document.getElementById('KitchenList')) {
  ReactDOM.render(<KitchenList />, document.getElementById('KitchenList'));
}
