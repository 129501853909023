import $ from 'jquery';

$(document).on('submit', '#newEsfForm', (e) => {

    $('#submitBtn').attr('disabled', 'disabled');
    $('#submitBtn').css('backgroundColor', '#A2A2A2');
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const el = $(document).find('#quField');

    let alco  = $('#alco').is(':checked')
    formData.append('alcohol', alco);
    formData.append('agreement', $('#formCheckBox').is(':checked'));

    if (alco === true && !$('#formCheckBox').is(':checked')) {
        el.next('.result-message').remove();
        el.after(
            $(`<div class='result-message alert alert-warning alert-block'>
                   <strong>Необходимо Ваше согласие с условиями публичной офертой</strong>
                </div>
             `),
        );
        return;
    }


    let date = e.target.date.value;
    if (date) {
        date = date.match(/.{1,2}/g).reverse();
        date[0] = (`20${date[0]}`);
        formData.append('date', date.join('-'));
    }
    if ($('#fileInput').val()) {
        formData.append('pa_file', $('#fileInput')[0].files[0]);
    }

    $.ajax({
        url: $('#newEsfForm').attr('action'),
        data: formData,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        type: 'POST',
        dataType: 'JSON',
        processData: false,
        contentType: false,
        success(r) {
            el.next('.result-message').remove();

            if (r.status === 'error_message') {

                el.after(
                    $(`
            <div class='result-message alert alert-${r.type} alert-block'>
              <strong>${r.message}</strong>
            </div>
          `),
                );

            } else if (r.status === 'success') {
                $('#receiptStep3').toggleClass('receiptRegister__step--active');
                $('#receiptStep2New').toggleClass('receiptRegister__step--deactive');
                $('#stepStatus2').removeClass('blockSteps__step--active');
                $('#stepStatus3').toggleClass('blockSteps__step--active');
            }
            $('#submitBtn').removeAttr('disabled');
            $('#submitBtn').css('backgroundColor', '#EA0000');
        },
        error(r) {
            const errorMessage = JSON.parse(r.responseText);

            $('.esf-validate-message').remove();
            $('.esf-validate-message-4').remove();
            $('.input-field').removeAttr('style');
            if (errorMessage) {
                for (const k in errorMessage) {
                    $(`.newEsf-${k}`).css('borderColor', '#dc3545');
                    if (k !== 'cash_n' && k !== 'shift_number' && k !== 'check_n' && k !== 'date') {
                        $(`.newEsf-${k}`).after(`<span class="esf-validate-message" style="color: #dc3545">${errorMessage[k]}</span>`);
                    } else {
                        $('.esf-validate-message-4').remove();
                        $('#quField').after(`<span class="esf-validate-message-4" style="color: #dc3545">${errorMessage[k]}</span>`);
                    }
                }
            }
            $('#submitBtn').removeAttr('disabled');
            $('#submitBtn').css('backgroundColor', '#EA0000');
        },
    });
});

// Function to handle form submission for checking
const handleCheckSubmission = (e) => {
    // $('#checkBtn').attr('disabled', 'disabled');
    // $('#checkBtn').css('backgroundColor', '#A2A2A2');
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(document.getElementById('newEsfForm'));

    if ($('#fileInput').val()) {
        formData.append('pa_file', $('#fileInput')[0].files[0]);
    }
    const el = $(document).find('#quField');

    $.ajax({
        url: checkApiUrl,
        data: formData,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        type: 'POST',
        dataType: 'JSON',
        processData: false,
        contentType: false,
        success(r) {
            el.next('.result-message').remove();
            if (r.status === 'error_message') {

                el.after(
                    $(`
                        <div class='result-message alert alert-${r.type} alert-block'>
                          <strong>${r.message}</strong>
                        </div>
                      `),
                );

            }

            if (r.status === 'success') {
                el.next('.result-message').remove();
                el.after(
                    $(`
                        <div class='result-message alert alert-${r.type} alert-block'>
                          <strong>${r.message}</strong>
                        </div>
                      `),
                );

                // Make readonly after check receipt
                $('.custom-select').css('pointer-events', 'none');
                $(".newEsf-cash_n").prop("readonly", true);
                $(".newEsf-shift_number").prop("readonly", true);
                $(".newEsf-date").prop("readonly", true);
                $(".newEsf-check_n").prop("readonly", true);

                if (r.check === 'alco_found') {

                    $('.contactForm__agreement--esf').css('display', 'block');
                    $('.contactForm__btn--esf--check').css('display', 'none');
                    $('.contactForm__btn--d-none').css('display', 'block');
                    $('#alco').prop("checked", true);
                }

                if (r.check === 'alco_notfound') {

                    $('.contactForm__btn--esf--check').css('display', 'none');
                    $('.contactForm__btn--d-none').removeClass('contactForm__btn--disabled').removeAttr("disabled");
                    $('.contactForm__btn--d-none').css('display', 'block');
                    $("#alco").prop("checked", false);
                }

            }
        },
        error(r) {
            const errorMessage = JSON.parse(r.responseText);

            $('.esf-validate-message').remove();
            $('.esf-validate-message-4').remove();
            $('.input-field').removeAttr('style');
            if (errorMessage) {
                for (const k in errorMessage) {
                    $(`.newEsf-${k}`).css('borderColor', '#dc3545');
                    if (k !== 'cash_n' && k !== 'shift_number' && k !== 'check_n' && k !== 'date') {
                        $(`.newEsf-${k}`).after(`<span class="esf-validate-message" style="color: #dc3545">${errorMessage[k]}</span>`);
                    } else {
                        $('.esf-validate-message-4').remove();
                        $('#quField').after(`<span class="esf-validate-message-4" style="color: #dc3545">${errorMessage[k]}</span>`);
                    }
                }
            }
            $('#submitBtn').removeAttr('disabled');
            $('#submitBtn').css('backgroundColor', '#EA0000');
        },
        complete() {
            // Common logic after both success and error
        },
    });
};

// Event listener for the check button
$(document).on('click', '#checkBtn', (e) => {
    handleCheckSubmission(e);
});
