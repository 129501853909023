/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');
} catch (e) {}

require('./jquery.min');
require('./jquery.magnific-popup.min');
require('./slick.min');
require('./content-editable');
require('./authentication');
require('./script');
require('./jquery.flurry.js');
require('../components/Image');
require('../components/Attachment');
require('../components/InvoiceDetailModal');
require('../components/KitchenList');
require('../components/PhoneMask');
require('./inputmask');
require('./custom-select');
require('./multiple-files.js');
require('./jquery.parallax-scroll');
require('./esf');
require('./newEsf');
require('./city-modal-lunch');
require('./mfpmodal');

window.StoriesStart = require('./zuck.min');

